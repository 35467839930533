import React, { useEffect, useState } from "react"
import Image from "next/image"
import image from "./../../public/green-check.png"
import Link from "next/link"
import axios from "axios"
import { urlGenerateToken } from "../utils/server-links"
import { useCustomTranslation } from '@utils/translations';
import { XMarkIcon } from '@heroicons/react/24/outline';

type ModalTermsProps = {
  onCallback: any
  show: any,
  legalDocs?: boolean
}


const ModalTerms = (props: ModalTermsProps) => {
  const { t } = useCustomTranslation();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [iframeKey, setIframeKey] = useState(0);
  const [embedded, setEmbedded] = useState(true);
  const [modalId, setModalId] = useState('')
  let { show, onCallback, legalDocs } = props

  useEffect(() => {
    if(show) {
      setIframeSrc(`https://docs.google.com/gview?embedded=true&url=${iframeModifyUrl(show)}`);
      if(show?.includes('.pdf')){
        setEmbedded(false);
      }
      if (show?.includes('locator')) {
        const modalLocator = show.split('&locator=')[1];
        setModalId(modalLocator)
      }
    }
    
  }, [show]);
  

  useEffect(() => {
    const interval = setInterval(() => {
      if (!iframeLoaded) {
        setIframeSrc(`https://docs.google.com/gview?embedded=true&url=${iframeModifyUrl(show)}`);
        setIframeKey(prevKey => prevKey + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [iframeLoaded, show]);

  const iframeModifyUrl = (iframeUrl: string) => {
    if (iframeUrl && typeof iframeUrl === "string" && iframeUrl.includes(".pdf")) {
      const fixProtocol = iframeUrl.startsWith("//") ? "https:" : "";
      const url = new URL(fixProtocol + iframeUrl);
      const hashParameters = "#statusbar=0&view=FitH";
      const modifiedUrl =
        url?.protocol + "//" + url?.hostname + url?.pathname + url?.search + hashParameters;
      return modifiedUrl;
    }
    return iframeUrl;
  };

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const handleCloseClick = () => {
    onCallback();
    setIframeLoaded(false);
  };
  return (
    <>
      {show && (
        <div onClick={(e)=>{e.preventDefault()}}>
          <div className="gray-overlay"></div>

          <div className="modal-terms-wrapper">
            <button className={`modal-terms-wrapper-close`} onClick={handleCloseClick}>
              <XMarkIcon className={'w-4 h-4'}></XMarkIcon>
            </button>
            <div className="line-background w-full h-full px-[10px] md:px-[20px] py-[20px] text-center overflow-auto flex flex-col items-center justify-between relative">
             {embedded ? 
             <div className={` w-full h-[90%] `}>
               <iframe id={modalId || undefined}  src={show} className="w-full h-full"></iframe>
             </div>
             :
             <>
             {!iframeLoaded && <div className="loader absolute left-1/2 top-1/2 -mt-[15px] -ml-[15px] xl:hidden"></div>}
             
              <div className="hidden xl:block w-full h-[90%]">
                <iframe id={modalId || undefined} src={iframeModifyUrl(show)} className="w-full h-full"></iframe>
              </div>
              <div className={`block xl:hidden  w-full h-[90%] ${!iframeLoaded && `opacity-0`}`}>
                <iframe id={modalId || undefined} key={iframeKey}  onLoad={handleIframeLoad} onError={handleIframeLoad} src={embedded ? iframeModifyUrl(show) : iframeSrc} className="w-full h-full"></iframe>
              </div>
              </>
              }

              <button onClick={handleCloseClick}
                className="bg-blue text-white inline-block w-[200px] rounded-[5px] mt-4 text-xl py-3 hover:bg-dark-blue font-semibold"
                type="button">
                <span className="relative" >{t('close.button')}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(ModalTerms)
