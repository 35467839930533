import Link from "next/link";
import parse, { domToReact, HTMLReactParserOptions } from "html-react-parser";
import { Fragment, useState } from "react";
import ModalTerms from "@components/Modal/ModalTerms";
import { useCustomTranslation } from "@utils/translations";
import useBrowserData from '@components/hooks/useBrowserData';

 // @ts-ignore
interface parseWithHTML extends HTMLElement {
  name: any;
  attribs?: any;
  prev?: any;
  children?: any;
}



function useParseWithHTML(text: any) {
  const [showPdfModal, setShowPdfModal] = useState('');
  const [showFullText, setShowFullText] = useState(false);
  const { t } = useCustomTranslation();
  const browserData = useBrowserData();

  function handleClick() {
    setShowFullText(!showFullText);
  }

  function addEmbeddedParam(url:string) {
      if(url.indexOf('embedded=') === -1 && url.includes('capex.com') && !url.includes('.pdf')){
        if (url.indexOf('?') === -1) {
            return url + "?embedded=true";
        } else {
            return url + "&embedded=true";
        }
      } else {
        return url
      }
  }

  function linkColor(url:string) {
    if (url === 'https://capex.com') {
      return 'text-red'
    } else {
      return 'text-blue'
    }
  }
  
  const options: HTMLReactParserOptions = {
    // @ts-ignore
    replace: ({ name, attribs, children, prev, ...rest }: parseWithHTML) => {
      if (name === "a" && attribs.href) {
        // if opens modal
        const changedUrl = addEmbeddedParam(attribs.href)
        if ((changedUrl.includes('.pdf') ) && attribs.notopenmodal !== 'true') {
          return <>
            <Link href={changedUrl} className={`font-semibold ${linkColor(attribs.href)} hover:underline`} onClick={(e) => { e.preventDefault(); setShowPdfModal(changedUrl);  }}>{domToReact(children, options)}</Link>
            {showPdfModal === changedUrl &&
              <ModalTerms show={showPdfModal} onCallback={()=>setShowPdfModal('')}  legalDocs/>
            }
          </>
        } 
        else if (attribs.openlegaldocs === 'true') {
          return <>
            <Link href={changedUrl} className={`font-semibold ${linkColor(attribs.href)} hover:underline`} onClick={(e) => { e.preventDefault(); setShowPdfModal(changedUrl);  }}>{domToReact(children, options)}</Link>
            {showPdfModal === changedUrl &&
            <ModalTerms show={showPdfModal} onCallback={()=>setShowPdfModal('')} legalDocs/>
        }
          </>
        } 


        if (changedUrl.includes('capex.com')) {
          return <>
          <Link href={changedUrl} className={`font-semibold ${linkColor(attribs.href)} hover:underline`} onClick={(e) => { e.preventDefault(); setShowPdfModal(changedUrl);  }}>{domToReact(children, options)}</Link>
          {showPdfModal === changedUrl &&
             <ModalTerms show={changedUrl} onCallback={()=>setShowPdfModal('')}/>
          }
          </>
        } else {
          if(browserData?.appName === 'Browser'){
            return  <Link href={changedUrl || "#"} target="_blank" className={`font-semibold ${linkColor(attribs.href)} hover:underline`}>{domToReact(children, options)}</Link>;
          } else {
            return <>
            <Link href={changedUrl} className={`font-semibold ${linkColor(attribs.href)} hover:underline`} onClick={(e) => { e.preventDefault(); setShowPdfModal(changedUrl);  }}>{domToReact(children, options)}</Link>
            {showPdfModal === changedUrl &&
               <ModalTerms show={changedUrl} onCallback={()=>setShowPdfModal('')} />
            }
            </>
          }
        }
       
        
      }
      if (name === "div" && attribs.class.includes('read_more_section')) {
          return <>
            <span className={`${linkColor(attribs.href)} cursor-pointer font-semibold md:hidden ${showFullText ? `hidden` : ``}`} onClick={()=>{setShowFullText(!showFullText);}}>...{t('read_more')}</span>
            <span className={`${showFullText?'':'hidden'} md:inline`}>{domToReact(children, options)}</span>
          </>
      }
    },
  };

  return <>{parse(text, options)}</>;
}

function ComponentHasNoData() {
  // new relic here
  return null;
}
interface HTMLRender {
  data: any;
  className?: any;
}

const HTMLRender = ({ data = '', className = "" }: HTMLRender) => {
  const parseHtml = useParseWithHTML(data);
  if (data && data !=='') {
    if (className && className !=="") return <span className={`rich-text ${className}`}>{parseHtml}</span>;
    return <>{parseHtml}</>;
  }
  return ComponentHasNoData();
};

export default HTMLRender;
