import { useCustomTranslation } from '@utils/translations';
import Image from 'next/image'
import React, { useState } from 'react'
import platformVidPlaceholder from "./../../public/background_video_platform_complete_placeholder.png";
import platformVid from "./../../public/background_video_platform_complete.gif";
import confety from "./../../public/succesful-registration.gif";
import HTMLRender from '@components/utils/htmlrender';
import ModalTerms from './ModalTerms';

const ModalFullyRegistered = ({ show, successHandler, user }: {
    user: any
    show: boolean
    successHandler: () => void
}) => {
    const { t } = useCustomTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showTermsModal, setShowTermsModal] = useState<any>(null);

    const onClose = (data: any) => {
        setShowTermsModal(false);
    };

    const handleScheduleClick = () => {
        let url = "https://calendly.com/capex-mena/30min";
        const params = new URLSearchParams();
    
        const fullNameParts = [];
        if (user && user.firstName && !user.firstName.includes('*')) {
            fullNameParts.push(user.firstName);
        }
        if (user && user.lastName && !user.lastName.includes('*')) {
            fullNameParts.push(user.lastName);
        }
    
        if (fullNameParts.length > 0) {
            params.append('name', fullNameParts.join(' '));
        }
    
        if (user && user.email && !user.email.includes('*')) {
            params.append('email', user.email);
        }
        if (user && user.phoneNumber && !user.phoneNumber.includes('*')) {
            params.append('location', user.phoneNumber);
        }
    
        url += `?${params.toString()}`;
        setShowTermsModal(url); 
    };

    return (
        show ? (
            <>
                <div className="gray-overlay blur-sm lg:blur-md ">
                    <Image
                        className="w-full h-full block object-cover align-middle"
                        src={platformVid.src}
                        alt=""
                        width="0"
                        height="0"
                        sizes="100vw"
                        placeholder="blur"
                        blurDataURL={platformVidPlaceholder.src}
                    />
                    <div className="absolute w-full h-full  z-10 bg-black top-0 left-0 opacity-25"></div>
                </div>
                <div id="modal_fully_reg_container" className="overflow-auto lg:overflow-hidden h-auto max-h-[85%] fixed  z-50  popup-modal bg-white md:w-[612px] w-[365px] max-w-[calc(100%-20px)]">
                    <div className="  z-0 top-0 left-0 px-20 py-5 flex  justify-center w-full h-full overflow-hidden">
                        <Image
                            className={`w-[200px] max-w-none inline-block  confetyImg`}
                            src={confety.src}
                            alt=""
                            width="0"
                            height="0" />
                    </div>
                    <div className="line-background px-[32px] lg:px-[20px] md:pb-[50px] py-[28px] items-center justify-center flex flex-col relative z-10 w-full pt-0">
                        <h2 id="modal_fully_reg_title" className="font-bold text-[2rem] text-center leading-[1.3] md:text-4xl text-dark-gray font-['Gilroy'] mb-4 ">{t('full_registration.completed.title')}</h2>
                        {
                            user && user?.companyId === 63 || user?.companyId === 65 ?
                                <p className="max-w-[360px] md:max-w-[100%] text-pretty mx-auto text-center text-[15px] leading-[18px] md:text-[19px] font-['Gilroy'] md:leading-[27px]"> <HTMLRender data={t('full_registration.completed.description_schedule_call')}/></p>
                                :
                                <p className="max-w-[360px] mx-auto text-center text-lg md:text-[20px] font-['Gilroy'] leading-[27px]">{t('full_registration.completed.description')}</p>
                        }
                        <div className={'flex flex-col w-full items-center justify-center mt-[20px] md:mt-[-30px] mx-auto gap-2'}>
                            {user && user?.companyId === 63 || user?.companyId === 65 ?
                                <>
                                    <button
                                        id="modal_fully_reg_schedule_call_btn"
                                        className={`bg-transparent inline-block w-[206px] text-md max-w-[80%] md:max-w-[48%] border-[1px] border-solid border-blue text-blue hover:text-white rounded-[5px] py-3 hover:bg-dark-blue font-semibold md:mt-[70px] mt-4 text-center ${isSubmitting ? `opacity-50 pointer-events-none` : ``}`}
                                        onClick={handleScheduleClick}
                                        type="button"
                                        disabled={isSubmitting}
                                    >
                                        <span className="relative">{t('accountCreated.scheduleCall')}</span>
                                    </button>
                                    <button
                                        id="modal_fully_reg_btn"
                                        className={`bg-blue text-white inline-block w-[206px] max-w-[80%] md:max-w-[48%]  rounded-[5px] text-md py-3 hover:bg-dark-blue font-semibold mt-0  ${isSubmitting ? `opacity-50 pointer-events-none` : ``}`}
                                        type="button"
                                        disabled={isSubmitting}
                                        onClick={() => { successHandler(); setIsSubmitting(true) }}
                                    >
                                        <span className="relative">{t('accountCreated.goToPlatformButton')}</span>
                                    </button>
                                </>
                                :
                                <button
                                    id="modal_fully_reg_btn"
                                    className={`bg-blue text-white inline-block w-[206px] max-w-[80%] md:max-w-[48%]  rounded-[5px] text-md py-3 hover:bg-dark-blue font-semibold md:mt-[70px] mt-4 ltr:md:mr-6 rtl:md:ml-6 ${isSubmitting ? `opacity-50 pointer-events-none` : ``}`}
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={() => { successHandler(); setIsSubmitting(true) }}
                                >
                                    <span className="relative">{t('accountCreated.goToPlatformButton')}</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div id="book-a-call-modal">
                <ModalTerms
                    show={showTermsModal}
                    onCallback={onClose}
                />
                </div>
            </>
        ) : null
    )
}

export default ModalFullyRegistered
