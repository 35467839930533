import "./ZendeskChat.module.css";
import Script from "next/script";
import React, { useEffect } from "react";
import get from "lodash/get";
import Tenants from "./configurations";
import { useCustomTranslation } from '@utils/translations';
import { useMediaQuery } from '@mantine/hooks';

const getLicenseName = (licenseId) => {
  switch (licenseId) {
      case 49: 
        return "cysec";
      case 63: // ADGM languages
      case 65: // ADGM languages
        return "adgm";
      case 62: // South Africa (FSCA) languages
      case 66: // South Africa (FSCA) languages
        return "fsca";
      case 57: // Seychelles (FSA) languages
      case 60: // Seychelles (FSA) languages
        return "fsa";
      default:
        return "cysec";
  }
} 

const ZendeskChat = React.memo(function ZendeskChat({ license, locale, zendeskVisibility }) {
  const tenantUrl = get(Tenants(license, locale), "url", false);
  const { t } = useCustomTranslation();
  // use mantine to check if isMobile
  const isMobile = useMediaQuery('(max-width: 768px)');

  // toggle zdk chat based on device and cms configuration
  useEffect(() => {
    if (!tenantUrl || (isMobile && !zendeskVisibility.showMobile) || (!isMobile && !zendeskVisibility.showDesktop)) {
      if (window && window?.zE) {
        try {
          window?.zE("webWidget", "hide");
        } catch (err) {
          console.warn('[Zendesk] WebWidget is not present on this license.');
        }
        try {
          window?.zE("messenger", "hide");
        } catch (err) {
          console.warn('[Zendesk] messenger is not present on this license.');
        }
      }
    } else {
      if (window && window?.zE) {
        try {
          window?.zE("webWidget", "show");
        } catch (err) {
          console.warn('[Zendesk] WebWidget is not present on this license.');
        }
        try {
          window?.zE("messenger", "show");
        } catch (err) {
          console.warn('[Zendesk] messenger is not present on this license.');
        }
      }
    }
  }, [tenantUrl, isMobile, zendeskVisibility]);

  // dont return script if configured to be hidden on current device
  if (!tenantUrl || (isMobile && !zendeskVisibility.showMobile) || (!isMobile && !zendeskVisibility.showDesktop)) {
    return null;
  }
  
  return (
    <Script
      src={tenantUrl}
      strategy="lazyOnload"
      onLoad={() => {
        window.zESettings = {
          webWidget: {
            chat: {
              prechatForm: {
                greeting: {
                  "*": t(`${getLicenseName(license)}_zendesk_greeting`),
                },
                departmentLabel: {
                  "*": t("language"),
                },
              },
              offlineForm: {
                greeting: {
                  "*": t(`${getLicenseName(license)}_zendesk_offline_greeting`),
                },
              },
            },
            launcher: {
              chatLabel: {
                "*": t(`${getLicenseName(license)}_zendesk_chat`),
              },
            },
          },
        };
      }}
      id="ze-snippet"
    />
  );
});

export default ZendeskChat;
