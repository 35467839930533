export default (license, locale) => {
  switch (license) {
    case 49: // CySEC languages
      switch (locale) {
        case "cz":
        case "en":
        case "de":
        case "it":
        case "hu":
        case "pl":
        case "ro":
        case "es":
        case "el":
        case "ae-ar":
        case "ae":
        case "za":
        case "ar":
        case "lat":
        case "vn":
          return { url: "https://static.zdassets.com/ekr/snippet.js?key=30a479be-f87d-47e5-95ff-2116169c0941" };
        default:
          return false;
      }
    case 63: // ADGM languages
    case 65: // ADGM languages
      switch (locale) {
        case "cz":
        case "en":
        case "de":
        case "it":
        case "hu":
        case "pl":
        case "ro":
        case "es":
        case "el":
        case "ae-ar":
        case "ae":
        case "za":
        case "ar":
        case "lat":
        case "vn":
          return { url: "https://static.zdassets.com/ekr/snippet.js?key=822e18dc-bd62-4393-bfa5-d936885eede5" };
        default:
          return false;
      }
    case 62: // South Africa (FSCA) languages
    case 66: // South Africa (FSCA) languages
      switch (locale) {
        case "cz":
        case "en":
        case "de":
        case "it":
        case "hu":
        case "pl":
        case "ro":
        case "es":
        case "el":
        case "ae-ar":
        case "ae":
        case "za":
        case "ar":
        case "lat":
        case "vn":
          return { url: "https://static.zdassets.com/ekr/snippet.js?key=de157e83-e2ac-4e05-a7fb-f3f6e25b941f" };
        default:
          return false;
      }
    case 57: // Seychelles (FSA) languages
    case 60: // Seychelles (FSA) languages
      switch (locale) {
        case "cz":
        case "en":
        case "de":
        case "it":
        case "hu":
        case "pl":
        case "ro":
        case "es":
        case "el":
        case "ae-ar":
        case "ae":
        case "za":
        case "ar":
        case "lat":
        case "vn":
          return { url: "https://static.zdassets.com/ekr/snippet.js?key=3cb53f08-bb1e-4ecc-b80d-817dcb81201c" };
        default:
          return false;
      }
    default:
      return false;
  }
};
