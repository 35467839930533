// This function returns a function that pushes data to the dataLayer.
// The function returned by the useGtagLayer hook is called pushGtagData,
// and it is passed an object that contains the data to be pushed to the
// dataLayer.

import { useEffect } from 'react';
import useBrowserData from './useBrowserData';

function getUserDevice(os:any) {
    if (os) {
      if (os.includes('iPhone')) {
        return "IOS"
      } else if (os.includes('Android')) {
        return "Android";
      } else {
        return "Desktop"
      }
    }
}

const useGtagLayer = () => {
    const browserData = useBrowserData();
    const pushGtagData = async (eventData: object) => {
      const device = getUserDevice(browserData?.operatingSystem);
      const userEnv = {
          userDevice: device,
          userPlatform: browserData?.appName === 'Browser' ? 'Web' : 'App',
      }
        if (typeof window === 'undefined') return;
        // @ts-ignore
        window.dataLayer.push(eventData);
        // make a request to the api
        try {
            await fetch(`${process.env.NEXT_PUBLIC_BE_HOST}/srvevnt`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...eventData, ...userEnv}),
            });
        } catch (error) {
            console.log('[Server Events] Failed to send event to server');
        }
        
    };

    useEffect(() => {
        if (typeof window === 'undefined') return;
        // @ts-ignore
        if (!window.dataLayer) {
            // @ts-ignore
            window.dataLayer = [];
        }
    }, []);

    return { pushGtagData };
};

export default useGtagLayer;
