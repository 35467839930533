import React from 'react';
import styles from './ButtonLoader.module.css';
import { useCustomTranslation } from '@utils/translations'; 

interface ButtonProps {
    isLoading?: boolean
    isDisabled: boolean
    buttonText: string
    buttonType?: "button" | "submit" | "reset" | undefined
    onClicked?: any
    isHidden?: boolean
    isFullWidth?: boolean
    classes?: any
    id?: string
}

const ButtonLoader = (props: ButtonProps) => {
    const { isLoading, isDisabled, buttonText, buttonType, onClicked, isHidden, classes, isFullWidth, id } = props;
    const { t } = useCustomTranslation();

    return (
        <div
            className={`text-center ${isFullWidth ? 'w-full lg:w-auto' : ''} ${isHidden ? 'h-0 opacity-0' : ''}`}
        >
            <button id={id} type={buttonType ? buttonType : 'submit'} onClick={onClicked ? onClicked : undefined}
                className={`${classes ? classes : 'w-full'}  rounded-[5px] text-xl duration-300 hover:bg-dark-blue transition-all relative py-3 ${isLoading ? styles.buttonLoading : styles.buttonNormal} ${isDisabled ? 'bg-blue opacity-20 pointer-events-none' : 'bg-blue'}
                items-center md:after:relative md:after:top-[0.05em] md:after:z-[1] md:after:inline-block md:after:h-0 md:after:w-0 md:after:border-t-[0.28em] md:after:border-b-[0.28em] md:after:border-t-transparent md:after:border-b-transparent md:after:align-middle md:after:opacity-0 md:after:transition-[margin,opacity] md:after:duration-300 md:after:ease-InOutBack hover:md:after:opacity-100 ltr:md:after:-ml-[0.35em] ltr:md:after:border-l-[0.35em] ltr:md:after:border-l-current ltr:hover:md:after:ml-[1em] rtl:text-center rtl:md:after:-mr-[0.35em] rtl:md:after:border-r-[0.35em] rtl:md:after:border-r-current rtl:hover:md:after:mr-[1em] md:after:mb-[0.15em]
                text-center `}
                disabled={isDisabled}
            >
                    <span className={`relative inline-block ${isLoading && 'opacity-0'}`}>{buttonText}</span>
                    {isLoading && (
                        <div className={`rounded-[5px] px-[24px] py-[10px] block button-loader  ${styles.loader}`}>
                            <div className="loader"></div>
                        </div>)
                    }
            </button>
        </div>
        
    );
}

export default React.memo(ButtonLoader);
