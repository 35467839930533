
import { useTranslation } from 'next-i18next';
import unescape from "lodash/unescape";
import escape from "lodash/escape";
import isString from "lodash/isString";

export function useCustomTranslation() {
    const { t: defaultTranslationFunction } = useTranslation();
    const t = (translationString: string, cleanText = true) => {
        // get the translation from next-i18next t()
        let translationValue = defaultTranslationFunction(translationString);
        // format the translation as we need
        let result = unescape(isString(translationValue) ? translationValue : "");
        result = isString(result) && cleanText ? result?.replace(/<(|\/)p>/g, "").replace(/&nbsp;/g, " ") : result;
        return result.trim();
    }
    // variable example in string: {{variable}}
    const replaceTemplate = (string: string, objectReplacement: any) => {
        let result = string;
        for (let key in objectReplacement) {
            if (objectReplacement.hasOwnProperty(key)) {
                const regex = new RegExp(`{{${key}}}`, 'g');
                result = result.replace(regex, objectReplacement[key]);
            }
        }
        return result;
    }
    return {
        t,
        replaceTemplate
    }
}
