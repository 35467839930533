import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import useStore from "./../store";
import { useCustomTranslation } from '@utils/translations';
import importantNotice from "./../../public/important-notice.svg";
import { License } from '../interfaces/types';
import HTMLRender from "@components/utils/htmlrender";



const ModalSessionExpiration = () => {
    const { t } = useCustomTranslation()
    const [buttonPressed, setButtonPressed] = useState(false);
    const [show, setShow] = useState(false);

    // Open ModalSessionExpiration if user is idle for 15min
    const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const handleUserActivity = () => {
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }
        inactivityTimeoutRef.current = setTimeout(() => {
            setShow(true)
        }, 30 * 60 * 1000);
        };

        handleUserActivity()
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        return () => {
        if (inactivityTimeoutRef.current) {
            clearTimeout(inactivityTimeoutRef.current);
        }
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        };
    }, []);
    return (
        <>
            {show && (
                <div className='fixed top-0 left-0 w-full h-full z-[999]'>
                    <div className="gray-overlay"></div>
                    <div className="overflow-auto fixed h-auto max-h-[88%] w-[calc(100%-20px)] z-50 popup-modal bg-white md:max-w-[700px] lg:max-w-[800px] max-w-[365px]">
                        <div className="line-background py-[50px] text-center relative max-w-[680px]  mx-auto">
                            <div className='w-full'>
                                <HTMLRender data={t("refresh_modal_text")} />
                            </div>

                            <div className="flex justify-center">
                                {buttonPressed ?
                                    <div className='loader mt-6' />
                                    :
                                    <button
                                        onClick={() => { setButtonPressed(true); window.location.reload() }}
                                        className="bg-blue text-white inline-block w-[201px] rounded-[5px] text-xl py-3 hover:bg-dark-blue font-semibold mt-6"
                                        type="button"
                                    >
                                        <span id="popup_licence_change_btn" className="relative">{t('refresh_modal_button')}</span>
                                    </button>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(ModalSessionExpiration);
