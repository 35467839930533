import React, {Fragment, useEffect, useState} from 'react';
import {Transition} from '@headlessui/react';
import { useCustomTranslation } from '@utils/translations'; 
import ButtonLoader from '../Buttons/ButtonLoader';
import styles from './Notification.module.css'
import useStore from '@components/store';

interface NotificationProps {
    showNotification: boolean;
    onContinue: (data: boolean) => void;
    onSaveAndExit: () => void;
}

const Notification = (props: NotificationProps) => {
    const { t } = useCustomTranslation();
    const { showNotification, onContinue, onSaveAndExit } = props;
    const [show, setShow] = useState(false);

    const handleContinue = () => {
        onContinue(showNotification);
    }

    const handleSaveAndExit = () => {
        onSaveAndExit();
    }

    const isSubmitting = useStore(state => state.isSubmitting);

    useEffect(() => {
        setShow(showNotification);
    }, [showNotification]);

    return (
        <div
            aria-live="assertive"
            className="z-[3] pointer-events-none left-1/2 -translate-x-1/2 lg:left-auto lg:-translate-x-0 lg:ltr:right-1 lg:rtl:left-1 2xl:ltr:right-[10rem] 2xl:rtl:left-[10rem] absolute top-[165px] lg:top-[100px] flex max-w-[calc(100%-2.5rem)] w-full md:w-auto items-end sm:items-start"
        >
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                <Transition
                    show={show}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="relative pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-2xl md:shadow-xl ring-1 ring-black ring-opacity-5">
                        <div className="py-5 md:py-[1.5rem] px-5 md:px-[1.5rem]">
                            <div className="flex flex-col">
                                <div className="w-full mb-5">
                                    <h3 className={`text-[#050505] font-semibold text-lg`}>{t('navbar.registrationNotCompleted')}</h3>
                                    <p className={`mt-5 text-[#050505] text-md font-normal`}>{t('navbar.continue')}</p>
                                </div>
                                <div className={`flex flex-col lg:flex-row justify-between ${styles.customButton} -mx-[0.5rem]`}>
                                    <ButtonLoader
                                        classes={`py-[6.5px] sm:py-3 px-2 text-md border-[#E5E5E5] border bg-transparent text-[#111114] hover:text-white  overflow-hidden w-full max-w-[calc(100%-1rem)] mb-2  lg:mb-0 ${styles.buttonWhite}`}
                                        buttonType='button'
                                        buttonText={t("notification.saveAndExit")}
                                        onClicked={handleSaveAndExit}
                                        isDisabled={isSubmitting}
                                        />
                                        
                                    <ButtonLoader
                                        classes={`py-[6.5px] sm:py-3 px-2 text-md overflow-hidden w-full max-w-[calc(100%-1rem)] text-white ${isSubmitting ? `opacity-50 pointer-events-none` : ``}`}
                                        buttonType='button' buttonText={t("notification.continue")}
                                        onClicked={handleContinue}
                                        isDisabled={isSubmitting}
                                        />
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default Notification;