import useStore from '@components/store';
import axios from 'axios';
import { useCookies } from '../utils/helpers';
import { urlGenerateToken, urlGetAccounts } from '../utils/server-links';
import useGtagLayer from '@components/hooks/useGtagLayer';
import useBrowserData from './useBrowserData';


const useRedirectToPlatform = (disabledOnboarding = false, customPath="", onboardingIsInternalIframe = false, dmaLogin = false ) => {
  const storeToken = useStore(state => state.token);
  const user = useStore(state => state.user);
  const currentScreenName = useStore(state => state.currentScreenName);
  const activeQuestion = useStore(state => state.activeQuestion);
  const activeQuestionName = useStore(state => state.activeQuestionName);
  const globalConfigurations = useStore(state => state.globalConfigurations);
  const isInsidePlatform = useStore(state => state.isInsidePlatform);
  const setIsSubmitting = useStore(state => state.setIsSubmitting);
  const { pushGtagData } = useGtagLayer();
  const saveQuizOnExit = useStore(state => state.saveQuizOnExit)
  const quizTemporary = useStore(state => state.quizTemporary)
  const browserData = useBrowserData();
  const getCookieHook = useCookies();
  
  const redirectToPlatform = async () => {
    setIsSubmitting(true)
    const token: string | null = getCookieHook('token');
    if(currentScreenName === "quiz"){
      saveQuizOnExit(quizTemporary?.data , quizTemporary?.quizId, quizTemporary?.instrumentyTypeId)
    }
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
        'Screen-Origin-Url': window?.location?.href
      },
    });

    const {data} = await instance.get(`${process.env.NEXT_PUBLIC_BE_HOST}${urlGetAccounts}`);

    if ((((typeof window?.webFunnel?.isIframe === 'undefined' || !window?.webFunnel?.isIframe) && !isInsidePlatform) || onboardingIsInternalIframe  ) && browserData?.appName!=='CAPEX app') {
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
          'Screen-Origin-Url': window?.location?.href
        },
      });
      try{
        const response = await instance.post(`${process.env.NEXT_PUBLIC_BE_HOST}${urlGenerateToken}`, { disabledOnboarding, dmaLogin });
        if (response?.data?.url) {
          await pushGtagData({
            Identifier: user?.customerId,
            companyId: user?.companyId,
            event: 'exit_onboarding',
            event_category: 'onboarding',
            stepTitle: currentScreenName,
            ...(currentScreenName === 'quiz' && { questionOrder: activeQuestion, questionName: activeQuestionName }),
          });
          if (disabledOnboarding) {
            // this is a workaround to login the customer in the platform and then to redirect it to the disabled onboarding
            var iframe = document.createElement("iframe");
            iframe.src = response?.data?.url;
            iframe.style.width = "1px";
            iframe.style.height = "1px";
            document.body.appendChild(iframe);

            setTimeout(function () {

              if (process.env.NEXT_PUBLIC_APP_ENV === 'local' || process.env.NEXT_PUBLIC_APP_ENV === 'development') {
                window.location.href = `https://cosmos-capex-live.az-qa.com/trading-platform/?funnel.registration.enabled=false${customPath}`;
              } else {
                window.location.href = `https://live-cosmos.capex.com/trading-platform/?funnel.registration.enabled=false${customPath}`;
              }
              console.log("redirectUrl", `https://cosmos-capex-live.az-qa.com/trading-platform/?funnel.registration.enabled=false${customPath}`)
            }, 3000);
          } else {
            if (onboardingIsInternalIframe) {
              if (window.top && window.top.location) {
                window.top.location.href = response?.data?.url;
              }
            } else {
              if (window.location) {
                window.location.href = response?.data?.url+`${data?.latestNewAccount?.platformAccountId ? '&ta_id='+data?.latestNewAccount?.platformAccountId : ''}`;
              }
            }
            
          }
        } 
      } catch (error){
        setIsSubmitting(false)
      }
    } else if(window?.webFunnel?.isIframe && isInsidePlatform) {
      await pushGtagData({
        Identifier: user?.customerId,
        companyId: user?.companyId,
        event: 'exit_onboarding',
        event_category: 'onboarding',
        stepTitle: currentScreenName,
        ...(currentScreenName === 'quiz' && { questionOrder: activeQuestion, questionName: activeQuestionName }),
      });
      window.webFunnel.switchAndCloseAccount({...data?.lastCreated,...(data?.unsupported?.length>0 && {"deactivate_accounts_list": data?.unsupported?.map((item:any) => item?.platformAccountId)}), globalConfigurations});
      // if (data?.shouldSwitchAccount || data?.unsupported.length>0 || data?.latestNewAccount?.platformAccountId){
      // } else {
      //   window.webFunnel.close();
      // }
      // if(latestAccount?.data?.tradingAccountInstrumentType === "CFD"){
      //   if (process.env.NEXT_PUBLIC_APP_ENV === 'local' || process.env.NEXT_PUBLIC_APP_ENV === 'development') {
      //     window.parent.location.href = `https://cosmos-capex-live.az-qa.com/fxclient5/controllers/switching?ta_id=${latestAccount?.data?.platformAccountId}`;
      //   } else {
      //     window.parent.location.href = `https://live-cosmos.capex.com/fxclient5/controllers/switching?ta_id=${latestAccount?.data?.platformAccountId}`;
      //   }
      // } else {
      //   window.parent.location.href = `${document?.referrer}/fxclient5/controllers/switching?ta_id=${latestAccount?.data?.platformAccountId}`
      // }
    }
    else {
      window.webFunnel.switchAndCloseAccount({...data?.lastCreated,...(data?.unsupported?.length>0 && {"deactivate_accounts_list": data?.unsupported?.map((item:any) => item?.platformAccountId)}), globalConfigurations});
      // if (data?.shouldSwitchAccount || data?.unsupported.length>0){
      // } else {
      //   window.webFunnel.close();
      // }
      await pushGtagData({
        Identifier: user?.customerId,
        companyId: user?.companyId,
        event: 'exit_onboarding',
        event_category: 'onboarding',
        stepTitle: currentScreenName,
        ...(currentScreenName === 'quiz' && { questionOrder: activeQuestion, questionName: activeQuestionName }),
      });
      console.log('Close iframe');
    }
  };

  return redirectToPlatform;
};

export default useRedirectToPlatform;
