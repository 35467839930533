import { useEffect, useState } from "react";

interface BrowserData {
    userAgent: string;
    browser: string;
    appName: string;
    operatingSystem: any;
}

const useBrowserData = (): BrowserData | null => {
    const [browserData, setBrowserData] = useState<BrowserData | null>(null);

    useEffect(() => {
        const getBrowser = async (): Promise<void> => {
            const userAgent = window && window.navigator && window.navigator.userAgent || "";
            // Extracting operating system
            let operatingSystem: any;
            try {
                operatingSystem =
                    userAgent && userAgent.match(/\((.*?)\)/)
                        // @ts-ignore
                        ? userAgent.match(/\((.*?)\)/)[1]
                        : undefined;
            } catch (error) {
                operatingSystem = undefined;
            }

            const test = (regexp: RegExp): boolean => {
                return regexp.test(userAgent);
            };

            // Extracting browser name
            function getBrowserType(): string {
                if (test(/opr\//i)) {
                    return "Opera";
                } else if (test(/edg/i)) {
                    return "Microsoft Edge";
                } else if (test(/chrome|chromium|crios/i)) {
                    return "Google Chrome";
                } else if (test(/firefox|fxios/i)) {
                    return "Mozilla Firefox";
                } else if (test(/safari/i)) {
                    return "Apple Safari";
                } else if (test(/trident/i)) {
                    return "Microsoft Internet Explorer";
                } else if (test(/ucbrowser/i)) {
                    return "UC Browser";
                } else if (test(/samsungbrowser/i)) {
                    return "Samsung Browser";
                } else {
                    return "Unknown browser";
                }
            }

            // Extracting app name
            let appName = "Browser";
            if (/Android/i.test(operatingSystem)) {
                if (/Version\/4\.0/i.test(userAgent) || /vw/i.test(operatingSystem)) {
                    appName = "CAPEX app";
                }
            } else if (/iPhone/i.test(operatingSystem)) {
                if (getBrowserType() === "Unknown browser") {
                    appName = "CAPEX app";
                }
            }

            const envData: BrowserData = {
                userAgent,
                operatingSystem,
                browser: appName === "Browser" ? getBrowserType() : "WebKit",
                appName,
            };
            setBrowserData(envData);
        };
        getBrowser();
    }, []);

    return browserData;
};

export default useBrowserData;
